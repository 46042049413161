import React from 'react';
import ContentWrapper from '../../Components/Layout/ContentWrapper';
import {Heading, Button, Text, Row} from '../../Widgets';
import {Link} from 'gatsby';
import Item from '../../Components/BlogItem';
import {Row as AntRow, Col} from 'antd';
import useBlogList from '../../hooks/use-blog-list';

export default function BlogList(props) {
  const {label} = props.pageContext;

  const {records, labels, bottomItemOrientation} = useBlogList({label});

  return (
    <ContentWrapper>
      {!label && (
        <>
          <div style={{textAlign: 'center', marginBottom: 30}}>
            <Heading
              style={{
                padding: 10,
                borderBottom: '1px solid #eee',
                display: 'inline',
              }}>
              最新文章
            </Heading>
          </div>

          <AntRow
            gutter={[20, 20]}
            style={{alignSelf: 'stretch', marginBottom: 50}}>
            {records
              .sort((a1, a2) => (a1.created < a2.created ? 1 : -1))
              .slice(0, 3)
              .map((record) => (
                <Col xs={24} md={8} key={record.id}>
                  <Item
                    key={record.id}
                    record={record}
                    extraStyle={{}}
                    type="portrait"
                  />
                </Col>
              ))}
          </AntRow>
        </>
      )}
      <div style={{textAlign: 'center', marginBottom: 30}}>
        <Heading
          style={{
            padding: 10,
            borderBottom: '1px solid #eee',
            display: 'inline',
          }}>
          {label || '分類文章'}
        </Heading>
      </div>
      <Row style={{justifyContent: 'center', marginBottom: 30}}>
        {['全部文章', ...labels].map((_label, idx) => {
          const type =
            label && label === _label
              ? 'primary'
              : !label && idx === 0
              ? 'primary'
              : 'default';

          return (
            <Button
              key={_label}
              style={{
                margin: 10,
                padding: '4px 15px',
                height: 32,
                lineHeight: 1.5715,
              }}
              type={type}>
              <Link to={idx === 0 ? `/articles` : `/articles/${_label}`}>
                {_label}
              </Link>
            </Button>
          );
        })}
      </Row>

      <div style={{borderTop: '1px solid #e0e0e0', marginBottom: 70}}>
        {records.map((record) => (
          <Item
            key={record.id}
            record={record}
            extraStyle={{marginBottom: 10}}
            type={bottomItemOrientation}
          />
        ))}
      </div>
    </ContentWrapper>
  );
}
