import React, {useContext, useEffect, useState} from 'react';
import {Context} from '../AppContext';
import useDimension from './use-dimension';
const appConfig = require('../data.json');

export default function useBlogList({label}) {
  const app = useContext(Context);
  const {rwd} = useDimension();
  const {blogLabels} = app.state;
  const [records, setRecords] = useState([]);

  useEffect(() => {
    (async () => {
      app.actions.setLoading(true);
      try {
        const filters = [
          {
            label: {$not: {$regex: 'FAQ'}},
          },
          {
            label: {$not: {$regex: 'draft'}},
          },
        ];

        if (label) {
          filters.push({
            label: {$regex: label},
          });
        }
        let resp = await app.actions.getBlogs({
          query: {
            $and: filters,
          },
          sorting: ['-created'],
          projection: {
            content: 0,
            html: 0,
          },
        });

        setRecords(resp);
      } catch (ex) {
        console.warn(ex);
      }
      app.actions.setLoading(false);
    })();
  }, [app.actions, label]);

  return {
    bottomItemOrientation: rwd === 'mobile' ? 'portrait' : 'landscape',
    labels: blogLabels,
    records,
  };
}
